<template>
  <div class="flex-container added_history">
    <div class="full_name field">
      <label for="">Full Name</label>
      <input type="text" id="reference_full_name" v-model="tmp_reference.full_name" placeholder="Full Name" @blur="validate($event)" required />
    </div>
    <div class="relationship field">
      <label for="">Relationship</label>
      <input type="text" id="reference_relationship" v-model="tmp_reference.relationship" placeholder="Relationship" @blur="validate($event)" required />
    </div>
    <div class="company_name field">
      <label for="">Company</label>
      <input type="text" id="reference_company" v-model="tmp_reference.company" placeholder="Company" @blur="validate($event)" required />
    </div>
    <div class="title field">
      <label for="">Title</label>
      <input type="text" id="reference_title" required v-model="tmp_reference.title" placeholder="Title" />
    </div>
    <div class="contact field">
      <label for="">Email</label>
      <input type="text" id="reference_email" v-model="tmp_reference.contact.email" placeholder="Email" />
    </div>
    <div class="contact field">
      <label for="">Phone</label>
      <input type="text" id="reference_phone" v-model="tmp_reference.contact.phone" placeholder="Phone" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'applicationReferenceItem',
  props: ['reference'],
  data: () => ({
    tmp_reference: {}
  }),
  methods: {
    validate: function (item) {
      const $ = require('jquery')
      const field = $(item.currentTarget)
      this.field_check((field))
    }
  },
  created () {
    this.tmp_reference = this.reference
  }
}
</script>

<style scoped>
input[type="text"], input[type="number"], select {
  background-color: white;
  padding: 8px 15px;
  border-radius: 6px;
  font-size: 1.1em;
  text-transform: capitalize;
  margin-bottom: 10px;
  border: solid 3px transparent;
  width: 100%;
  display: block;
}
input[type="text"].field_error, select.field_error {
  background-color: rgba(255,125,125,1);
  color: white;
  border: solid 3px red;
}
label {
  display: block;
  width: 100%;
  margin: 0 0 6px 0;
  color: black;
}
.added_history {
  background-color: rgba(200,200,200,1) !important;
  padding-bottom: 15px !important;
}
.added_history:last-child {
  border-bottom: 0;
  margin-bottom: 20px !important;
}
.field { width: 49%; }
.contact > input { text-transform: none; }

@media screen and (max-width: 480px){
  .field { width: 100%; }
}
</style>
