<template>
  <div>
    <section class="main services">
      <div class="hero parallax"></div>

      <article>
        <h2>Employment Opportunities</h2>

        <div class="thumb"></div>
        <p class="intro">
          We are always on the lookout for dependable applications for armed and unarmed professional security guard positions to become a part of our successful team. GammaTeam Security is a professional security company that specializes in providing excellent customer service to our clients. We have an excellent training program which provides our security guards with the resources they need to provide superior service to our clients, as well as protecting their properties and valuables.<br /><br />
          If you are interested, please, click the apply button below and fill out our application. Please, keep in mind, you shall be a holder of valid PERC card and 20-hour unarmed training certificate in order to be considered for hiring*.
        </p>

        <div class="apply">
          <v-btn
              outlined
              x-large
              @click.stop="apply(1)"
          >
            APPLY TODAY
          </v-btn>
        </div>

        <div class="caption">
          * To speed up the process, after filling out our application, please, forward copies of your PERC card and 20-hour unarmed training certificate to info@gammateamcorp.com (additionally if you have: 20-hour armed training certificate and FOID card, requalification document if 20-hourarmed training certificate was received over 2 years ago from a present day)
        </div>
      </article>
    </section>

    <div id="application" v-show="show">
      <div class="title">
        <v-btn
          icon
          small
          class="close"
          @click.stop="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h3>{{ title }}</h3>
      </div>
      <div class="screens">
        <article v-if="application.step === 1" class="applicant">
          <div class="flex-container">
            <input type="text" id="first" v-model="application.applicant.first" placeholder="First Name" @blur="validate($event)" @keypress.enter="application_next" required />
            <input type="text" id="last" v-model="application.applicant.last" placeholder="Last Name" @blur="validate($event)" @keypress.enter="application_next" required />
            <input type="text" id="middle" v-model="application.applicant.middle" placeholder="Middle name" />
            <input
                type="text"
                id="dob"
                v-model="application.applicant.dob"
                placeholder="Date of Birth (mm/dd/yyyy)"
                @keyup="handle_date($event,'applicant','dob')"
                @blur="validate($event)"
                required
            />
            <input type="text" id="address1" v-model="application.applicant.address1" placeholder="Address 1" @blur="validate($event)" @keypress.enter="application_next" required />
            <input type="text" id="address2" v-model="application.applicant.address2" placeholder="Address 2" @keypress.enter="application_next" />
            <input type="text" id="city" v-model="application.applicant.city" placeholder="City" @blur="validate($event)" @keypress.enter="application_next" required />
            <select id="state" v-model="application.applicant.state" @blur="validate($event)" required>
              <option selected="selected">- Select State-</option>
              <option :value="state" v-for="(state, i) in $store.state.states" :key="'state_' + i">{{ state }}</option>
            </select>
            <input type="text" id="zip" v-model="application.applicant.zip" placeholder="Zip Code" @blur="validate($event)" @keypress.enter="application_next" required />
            <input type="text" id="email" v-model="application.applicant.email" placeholder="Email" @keypress.enter="application_next" />
            <input type="text" id="phone" v-model="application.applicant.phone" placeholder="Phone" @blur="validate($event)" @keypress.enter="application_next" required />
            <input type="text" id="drivers_license" v-model="application.applicant.drivers_license" placeholder="Driver's License" @blur="validate($event)" @keypress.enter="application_next" required />
          </div>
        </article>
        <article v-else-if="application.step === 2" class="position">
          <div class="flex-container">
            <input type="text" id="position_title" v-model="application.position.position_title" placeholder="Position Title" @keypress.enter="application_next" required />
            <input type="text" id="available_date" v-model="application.position.available_date" placeholder="Date Available (mm/dd/yyyy)" @keyup="handle_date($event,'position','available_date')" @blur="validate($event)" @keypress.enter="application_next" required />
            <input type="text" id="desired_pay" v-model="application.position.desired_pay" placeholder="Desired Pay (leave blank if unsure)" @keypress.enter="application_next" />
            <div class="checkbox_group">
              <span>* Employment Type:</span>
              <v-checkbox v-model="application.position.full_time" dark label="Full Time" dense hide-details ></v-checkbox>
              <v-checkbox v-model="application.position.part_time" dark label="Part Time" dense hide-details ></v-checkbox>
              <v-checkbox v-model="application.position.seasonal" dark label="Seasonal" dense hide-details ></v-checkbox>
            </div>
            <div class="disclaimer_text">*Full &amp; Part time positions are available but are not guaranteed.</div>
          </div>
        </article>
        <article v-else-if="application.step === 3" class="eligibility">
          <div class="flex-container">
            <div class="checkbox_instructions">Only check the box if applies to you</div>
            <div class="checkbox_group">
              <span>Are you legally eligible to work in US?
                <br /><span style="font-size: .8em; color: rgba(255,255,255,.5);">* Proof of eligibility will be required.</span>
              </span>
              <v-checkbox dark v-model="application.eligibility.us_eligible" dense hide-details></v-checkbox>
            </div>
            <div class="checkbox_group">
              <span>Have you ever worked for GammaTeam Security previously?
                <br /><span class="additional_info_disclaimer">* If yes, you'll be asked to provide further information.</span>
              </span>
              <v-checkbox dark v-model="application.eligibility.previous_employment.value" dense hide-details></v-checkbox>
            </div>
            <div class="additional_info" v-show="application.eligibility.previous_employment.value">
              <input type="text" class="half_width" placeholder="Employed FROM date (mm/dd/yyyy)" v-model="application.eligibility.previous_employment.date_from" @keypress.enter="application_next" />
              <input type="text" class="half_width" placeholder="Employed TO date (mm/dd/yyyy)" v-model="application.eligibility.previous_employment.date_to" @keypress.enter="application_next" />
            </div>
            <div class="checkbox_group">
              <span>Have you ever been convicted of a felony?
                <br /><span class="additional_info_disclaimer">* If yes, you'll be asked to provide further information.</span>
              </span>
              <v-checkbox dark v-model="application.eligibility.felony.value" dense hide-details></v-checkbox>
            </div>
            <div class="additional_info" v-show="application.eligibility.felony.value">
              <input type="text" class="full_width" placeholder="Describe previous conviction" v-model="application.eligibility.felony.description" @keypress.enter="application_next" />
            </div>
          </div>
        </article>
        <article v-else-if="application.step === 4" class="screening_process">
          <div class="flex-container">
            <div class="checkbox_instructions">Only check the box if applies to you</div>
            <div class="checkbox_group">
              <span>Have you ever been convicted of any offence by a civil or criminal court?</span>
              <v-checkbox dark v-model="screening_process.prior_conviction" dense hide-details></v-checkbox>
            </div>
            <div class="checkbox_group">
              <span>Have you ever received a formal caution from the police?</span>
              <v-checkbox dark v-model="screening_process.formal_caution" dense hide-details></v-checkbox>
            </div>
            <div class="checkbox_group">
              <span>Has any member of your family, near relative or common law partner ever been convicted of any offense?</span>
              <v-checkbox dark v-model="screening_process.relations_conviction" dense hide-details></v-checkbox>
            </div>
            <div class="checkbox_group">
              <span>Have you ever been dismissed from any employment?</span>
              <v-checkbox dark v-model="screening_process.terminated" dense hide-details></v-checkbox>
            </div>
            <div class="checkbox_group">
              <span>Have you ever declared bankruptcy?</span>
              <v-checkbox dark v-model="screening_process.bankruptcy" dense hide-details></v-checkbox>
            </div>
            <div class="checkbox_group">
              <span>Do you have any outstanding court judgements?</span>
              <v-checkbox dark v-model="screening_process.court_judgements" dense hide-details></v-checkbox>
            </div>
            <div class="checkbox_group">
              <span>Has your driver's license ever been revoked / suspended?</span>
              <v-checkbox dark v-model="screening_process.revoked" dense hide-details></v-checkbox>
            </div>
            <div class="checkbox_group">
              <span>Are you currently paying child support?</span>
              <v-checkbox dark v-model="screening_process.child_support" dense hide-details></v-checkbox>
            </div>
            <div class="checkbox_group">
              <span>Are you currently using any prescribed drugs/medication?</span>
              <v-checkbox dark v-model="screening_process.prescription_drugs" dense hide-details></v-checkbox>
            </div>
            <div class="checkbox_group">
              <span>Do you have any physical or mental impairements which has a substantial and long-term affect on your ability to carry out day to day activities?
                <br /><span class="additional_info_disclaimer">* If yes, you'll be asked to provide further information.</span>
              </span>
              <v-checkbox dark v-model="screening_process.impairments.value" dense hide-details></v-checkbox>
            </div>
            <div class="additional_info" v-show="screening_process.impairments.value">
              <input
                type="text"
                id="screening_impairments"
                placeholder="List any form of medication, drugs or treatment you are currently and/or are regularly receving"
                v-model="screening_process.impairments.notes"
              />
            </div>
          </div>
        </article>
        <article v-else-if="application.step === 5" class="education">
          <v-btn
              text
              v-if="!adding_item && !editing_item"
              @click.stop="add_education"
          >
            <v-icon>mdi-plus</v-icon>
            Click to add education
          </v-btn>
          <template v-if="adding_item || editing_item">
            <div class="add_cancel">
              <v-btn
                  outlined
                  @click.stop="cancel_add_education"
              >
                CANCEL
              </v-btn>
              <v-btn
                  outlined
                  id="save_education"
                  @click.stop="if (validate_section('education')) save_education()"
              >
                SAVE {{ (education.type != "-1" ? education.type : "") }}
              </v-btn>
            </div>
            <div class="flex-container">
              <applicationEducationHistoryItem :education="education"></applicationEducationHistoryItem>
            </div>
          </template>
          <template v-if="Object.keys(application.education).length > 0 && !adding_item && !editing_item">
            <div class="flex-container added_history headings">
              <span class="btns">&nbsp;</span>
              <span>Type</span>
              <span>Name</span>
              <span>City</span>
              <span>State</span>
              <span>Graduated</span>
            </div>
            <div v-for="(item, i) in application.education" class="flex-container added_history" :key="'education_collapsed_' + i">
              <span class="btns">
                <v-btn
                    icon
                    dark
                    small
                    @click.stop="edit_education(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    icon
                    dark
                    small
                    @click.stop="delete_education(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </span>
              <span>{{ item.type }}</span>
              <span>{{ item.name }}</span>
              <span>{{ item.location.city }}</span>
              <span>{{ item.location.state }}</span>
              <span>
                <v-icon v-if="item.history.graduated">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
              </span>
            </div>
          </template>
        </article>
        <article v-else-if="application.step === 6" class="employment">
          <v-btn
              text
              v-if="!adding_item && !editing_item"
              @click.stop="add_employment"
          >
            <v-icon>mdi-plus</v-icon>
            Click to add employment
          </v-btn>
          <template v-if="adding_item || editing_item">
            <div class="add_cancel">
              <v-btn
                  outlined
                  @click.stop="cancel_add_employment"
              >
                CANCEL
              </v-btn>
              <v-btn
                  outlined
                  id="save_employment"
                  @click.stop="if (validate_section('employment')) save_employment()"
              >
                SAVE
              </v-btn>
            </div>
            <applicationEmploymentHistoryItem :employment="employment"></applicationEmploymentHistoryItem>
          </template>
          <template v-if="Object.keys(application.employment).length > 0 && !adding_item && !editing_item">
              <div class="flex-container added_history headings">
                <span>&nbsp;</span>
                <span>Employer</span>
                <span>Job Title</span>
                <span>City</span>
                <span>State</span>
                <span>Employed From</span>
                <span>Employed To</span>
              </div>
              <div v-for="(item, i) in application.employment" class="flex-container added_history" :key="'employment_collapsed_' + i">
                <span>
                  <v-btn
                    icon
                    dark
                    small
                    @click.stop="edit_employment(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    dark
                    small
                    @click.stop="delete_employment(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </span>
                <span>{{ item.employer }}</span>
                <span>{{ item.title }}</span>
                <span>{{ item.location.city }}</span>
                <span>{{ item.location.state }}</span>
                <span>{{ item.history.from }}</span>
                <span>{{ item.history.to }}</span>
              </div>
          </template>
        </article>
        <article v-else-if="application.step === 7" class="references">
          <div class="flex-container">
            <span class="references_instructions">{{ reference_label_computed }}</span>
          </div>
          <v-btn
              text
              v-if="!adding_item && !editing_item"
              @click.stop="add_reference"
          >
            <v-icon>mdi-plus</v-icon>
            Click to add reference
          </v-btn>
          <template v-if="adding_item || editing_item">
            <div class="add_cancel">
              <v-btn
                  outlined
                  @click.stop="cancel_add_reference"
              >
                CANCEL
              </v-btn>
              <v-btn
                  outlined
                  id="save_reference"
                  @click.stop="if (is_valid_reference()) save_reference()"
              >
                SAVE
              </v-btn>
              <span class="reference_label">Adding {{ reference_idx_computed }} reference</span>
            </div>
            <applicationReferenceItem :reference="reference"></applicationReferenceItem>
          </template>
          <template v-if="Object.keys(professional_references).length > 0 && !adding_item && !editing_item">
            <div class="flex-container added_history headings">
              <span>&nbsp;</span>
              <span>Company</span>
              <span>Job Title</span>
              <span>Name</span>
              <span>Relationship</span>
            </div>
            <div v-for="(item, i) in professional_references" class="flex-container added_history" :key="'references_collapsed_' + i">
              <span>
                <v-btn
                    icon
                    dark
                    small
                    @click.stop="edit_reference(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    icon
                    dark
                    small
                    @click.stop="delete_reference(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </span>
              <span>{{ item.company }}</span>
              <span>{{ item.title }}</span>
              <span>{{ item.full_name }}</span>
              <span>{{ item.relationship }}</span>
            </div>
          </template>
        </article>
        <article v-else-if="application.step === 8" class="military">
          <div class="flex-container">
            <div class="checkbox_instructions">Only check the box if applies to you</div>
            <div class="checkbox_group">
              <span>Are you a veteran or are currently active in the military?
                <br /><span class="additional_info_disclaimer">* If yes, you'll be asked to provide further information.</span>
              </span>
              <v-checkbox dark v-model="military.veteran" dense hide-details></v-checkbox>
            </div>
            <div class="additional_info" v-if="military.veteran">
              <input type="text" id="military_discharge_rank" placeholder="Rank at discharge" v-model="military.discharge.rank" @blur="validate($event)" required />
              <input type="text" id="military_branch" placeholder="Branch of service" v-model="military.branch" @blur="validate($event)" required />
              <select id="military_discharge_type" v-model="military.discharge.type" @blur="validate($event)" required>
                <option value="-1">- Discharge Type -</option>
                <option value="honorable">Honorable</option>
                <option value="dishonorable">Dishonorable</option>
                <option value="other">Other</option>
              </select>
              <input type="text" id="military_discharge_from" placeholder="Dates served from" v-model="military.discharge.from" @blur="validate($event)" required />
              <input type="text" id="military_discharge_to" placeholder="Dates served to" v-model="military.discharge.to" @blur="validate($event)" required />
              <template v-if="military.discharge.type != 'honorable' && military.discharge.type">
                <input type="text" id="military_discharge_notes" placeholder="Reason for discharge" v-model="military.discharge.notes" @blur="validate($event)" required />
              </template>
            </div>
          </div>
        </article>
        <article v-else-if="application.step === 9" class="background_authorization">
          <div class="flex-container">
            <div class="legal">
              <p>The information contained in this application is correct to the best of my knowledge.</p>
              <p>
                I hereby authorize GammaTeam Security, Inc and its designated agents and representatives to conduct a comprehensive review of my background causing a consumer report and/or an investigative consumer report to be generated for employment and/or volunteer purposes. I understand that the scope of the consumer report/ investigative consumer report may include, but is not limited to the following areas: verification of social security number; credit reports, current and previous residences; employment history, education background, character references; drug testing, civil and criminal history records from any criminal justice agency in any or all federal, state, county jurisdictions; driving records, birth records, and any other public records.
              </p>
              <p>
                I further authorize any individual, company, firm, corporation, or public agency to divulge any and all information, verbal or written, pertaining to me, to GammaTeam Security, Inc or its agents. I further authorize the complete release of any records or data pertaining to me which the individual, company, firm, corporation, or public agency may have, to include information or data received from other sources. GammaTeam Security, Inc and its designated agents and representatives shall maintain all information received from this authorization in a confidential manner in order to protect the applicants personal information, including, but not limited to, addresses, social security numbers, and dates of birth.
              </p>
            </div>
            <div class="checkbox_group">
              <span>If offered a position, are you willing to consent to a background check?</span>
              <v-checkbox dark v-model="background_authorization.consent" dense hide-details @click.stop="scroll_background"></v-checkbox>
            </div>
            <template  v-if="background_authorization.consent">
              <div class="heading">
                <span>Previous addresses used (if any):</span>
                <v-btn
                  outlined
                  small
                  @click.stop="add_previous_address"
                >
                  ADD
                </v-btn>
              </div>
              <div class="previous_addresses">
                <template v-for="(item, i) in background_authorization.previous_addresses">
                  <div :key="`previous_address_` + i">
                    <input type="text" placeholder="Previous Address, City, State, Zip" v-model="item.address" @blur="validate($event)" required class="previous_address" />
                    <input type="text" placeholder="From" v-model="item.from" @blur="validate($event)" required class="previous_address_from" />
                    <input type="text" placeholder="To" v-model="item.to" @blur="validate($event)" required class="previous_address_to" />
                    <v-btn
                      icon
                      small
                      @click.stop="delete_previous_address(item)"
                      class="remove_previous_address"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </div>
                </template>
              </div>
              <div class="heading">
                <span>Previous name(s) used  (if any):</span>
                <v-btn
                  outlined
                  small
                  @click.stop="add_previous_name"
                >
                  ADD
                </v-btn>
              </div>
              <div class="previous_names">
                <template v-for="(item, i) in background_authorization.previous_names">
                  <div :key="`previous_name_` + i">
                    <input type="text" placeholder="Previous name" v-model="item.name" @blur="validate($event)" required class="previous_name" />
                    <input type="text" placeholder="From" v-model="item.from" @blur="validate($event)" required class="previous_name_from" />
                    <input type="text" placeholder="To" v-model="item.to" @blur="validate($event)" required class="previous_name_to" />
                    <v-btn
                      icon
                      small
                      @click.stop="delete_previous_name(item)"
                      class="remove_previous_name"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </article>
        <article v-else-if="application.step === 10" class="final_disclaimer">
          <div class="disclaimer_text">
            Applicant understands that GammaTeam Security is an Equal Opportunity Employer and is committed to excellence through diversity. In order to ensure this application is acceptable, please fully complete all sections EVEN IF you decide to provide a separate resume.<br /><br />
            I, the Applicant, certify that my answers are true and honest to the best of my knowledge. If this application leads to my eventual employment, I understand that any false or misleading information in my application or interview may result in my employment being terminated.
          </div>
          <div class="flex-container">
            <input type="text" id="disclaimer_signature" v-model="disclaimer.signature" placeholder="Sign your name" @blur="validate($event)" required style="width: 49%"/>
            <input type="text" id="disclaimer_date" disabled v-model="disclaimer.date" @blur="validate($event)" required style="width: 49%"/>
          </div>
        </article>
        <article v-else>
          We will review your application and will be reaching out to you shortly.<br /><br />
          <h3>Important Next step</h3>
          In a few minutes, you will be recieving an email at {{ application.applicant.email }} with our Employee Handbook and onboarding forms. <br />
          The email subject will be <span class="blue">"Important on-boarding forms from GammaTeam Security"</span><br /><br />
          <strong>Please make sure you check your email at {{ application.applicant.email }} and fill-out the onboarding forms.</strong><br />
          If you don't receive an on-boarding email from us in the next few minutes, make sure that you check your SPAM folder.
        </article>
      </div>
      <div class="actions">
        <template v-if="!complete">
          <span class="error_message">{{ error_message }}</span>
          <div class="progress_container" v-if="showSpinner">
            <span>Saving progress...</span>
            <v-progress-circular class="ml-3" indeterminate size="20"></v-progress-circular>
          </div>
          <v-btn
              outlined
              large
              @click.stop="application_prev"
              v-if="application.step > 1"
          >
            BACK
          </v-btn>
          <v-btn
              outlined
              large
              @click.stop="application_next"
              :disabled="showSpinner"
          >
            NEXT
          </v-btn>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import moment from 'moment'
import applicationEmploymentHistoryItem from '@/components/applicationEmploymentHistoryItem'
import applicationEducationHistoryItem from '@/components/applicationEducationHistoryItem'
import applicationReferenceItem from '@/components/applicationReferenceItem'
export default {
  name: 'Careers',
  components: { applicationEmploymentHistoryItem, applicationEducationHistoryItem, applicationReferenceItem },
  data: () => ({
    publicPath: process.env.BASE_URL,
    date: new Date().toISOString().substr(0, 10),
    showSpinner: false,
    complete: false,
    error_message: '',
    menu: false,
    modal: false,
    menu2: false,
    show: false,
    adding_item: false,
    editing_item: false,
    screening_process: {
      prior_conviction: false,
      formal_caution: false,
      relations_conviction: false,
      terminated: false,
      pending_prosecutions: false,
      bankruptcy: false,
      court_judgements: false,
      license_revoke: false,
      child_support: false,
      prescription_drugs: false,
      impairments: {
        value: false,
        notes: ''
      }
    },
    education: {
      type: -1,
      name: '',
      location: {
        city: '',
        state: -1
      },
      history: {
        from: '',
        to: '',
        graduated: 'No'
      }
    },
    employment: {
      employer: '',
      title: '',
      duties: '',
      notes: '',
      phone: '',
      location: {
        city: '',
        state: -1
      },
      pay: {
        start: '',
        end: ''
      },
      history: {
        from: '',
        to: ''
      }
    },
    reference: {
      full_name: '',
      relationship: '',
      company: '',
      title: '',
      contact: {
        email: '',
        phone: ''
      }
    },
    application: {
      step: 1,
      applicant: {
        first: '',
        last: '',
        middle: '',
        dob: '',
        address1: '',
        address2: '',
        city: '',
        state: 'Illinois',
        zip: '',
        email: '',
        phone: '',
        drivers_license: ''
      },
      position: {
        position_title: '',
        available_date: '',
        desired_pay: '',
        full_time: false,
        part_time: false,
        seasonal: false
      },
      eligibility: {
        us_eligible: false,
        previous_employment: {
          value: false,
          date_from: '',
          date_to: ''
        },
        felony: {
          value: false,
          description: ''
        }
      },
      education: [

      ],
      employment: [

      ]
    },
    professional_references: [

    ],
    military: {
      veteran: false,
      active: false,
      branch: '',
      discharge: {
        rank: '',
        type: -1,
        from: '',
        to: '',
        notes: ''
      }
    },
    background_authorization: {
      consent: false,
      previous_names: [],
      previous_addresses: []
    },
    previous_name: {
      name: '',
      from: '',
      to: ''
    },
    previous_address: {
      address: '',
      from: '',
      to: ''
    },
    disclaimer: {
      signature: '',
      date: ''
    }
  }),
  methods: {
    apply: function (step) {
      if (store.state.application_id > 0) {
        console.log('Previous unfinished application exists, continue')
        this.requestHandler.doGet('application', '/' + store.state.application_id)
          .then(response => {
            const _application = response.data.data
            this.show = true
            this.application = _application
            if (typeof _application.professional_references !== 'undefined') {
              this.professional_references = _application.professional_references
            }
            if (typeof _application.military !== 'undefined') {
              this.military = _application.military
            }
            if (typeof _application.background_authorization !== 'undefined') {
              this.background_authorization = _application.background_authorization
            }
            this.setOverlayStatus(true, false, false)
          })
      } else {
        this.show = true
        this.application.step = step
        this.setOverlayStatus(true, false, false)
      }
    },
    close: function () {
      this.setOverlayStatus(false, false, false)
      this.show = false
    },
    handle_date: function (e, section, field) {
      const _value = this.application[section][field].replaceAll('/', '')
      if (e.key && isNaN(e.key)) {
        this.application[section][field] = this.application[section][field].replaceAll(e.key, '')
      } else {
        const date = {
          month: _value.substring(0, 2),
          day: _value.substring(2, 4),
          year: _value.substring(4, 8)
        }
        this.application[section][field] = date.month
        if (date.day.length) this.application[section][field] += '/' + date.day
        if (date.year.length) this.application[section][field] += '/' + date.year
      }
    },
    save: function () {
      return new Promise(function (resolve, reject) {
        if (this.application.step === 1) {
          this.requestHandler.doPost('application', '/', {
            data: this.application
          })
            .then(response => {
              console.log(response)
              if (response.data.success) {
                store.commit('updateApplicationId', {
                  application_id: response.data.data.id
                })
                console.log('Updated application_id to ' + store.state.application_id)
                resolve(true)
              }
            })
            .catch(error => {
              reject(error)
            })
        } else {
          const _clone = Object.assign({}, this.application)
          _clone.professional_references = this.professional_references
          _clone.screening_process = this.screening_process
          _clone.military = this.military
          _clone.background_authorization = this.background_authorization
          _clone.disclaimer = this.disclaimer
          this.requestHandler.doPut('application', '/', {
            id: store.state.application_id,
            complete: this.complete,
            data: _clone
          })
            .then(response => {
              console.log(response)
              if (response.data.success) {
                resolve(true)
              }
            })
            .catch(error => {
              reject(error)
            })
        }
      }.bind(this))
    },
    application_prev: function () {
      if (this.application.step > 1) this.application.step--
      else this.application.step = 1
    },
    scroll_background: function () {
      const $ = require('jquery')
      const screens = $('.screens')
      const scrollHeight = (this.background_authorization.consent ? screens.height() + 100 : 0)
      console.log('scrollHeight: ' + scrollHeight)
      setTimeout(function () {
        screens.scrollTop(scrollHeight)
      }, 250)
    },
    application_next: function () {
      let section = ''
      this.showSpinner = true
      if (this.application.step === 1) section = 'applicant'
      else if (this.application.step === 2) section = 'position'
      else if (this.application.step === 3) section = 'eligibility'
      else if (this.application.step === 4) section = 'screening_process'
      else if (this.application.step === 5) section = 'education'
      else if (this.application.step === 6) section = 'employment'
      else if (this.application.step === 7) section = 'references'
      else if (this.application.step === 8) section = 'military'
      else if (this.application.step === 9) section = 'background_authorization'
      else if (this.application.step === 10) section = 'disclaimer'

      // validate
      if (this.validate_section(section)) {
        if (this.application.step === 5 && this.adding_item) this.save_education()
        if (this.application.step === 6 && this.adding_item) this.save_employment()
        if (this.application.step === 7 && this.adding_item) this.save_reference()

        this.adding_item = false
        this.editing_item = false

        // application is finalized
        if (this.application.step === 10) {
          this.complete = true
          this.application.step++
        }

        // save
        const instance = this
        this.save()
          .then(response => {
          // advance
            if (response) {
              switch (instance.application.step) {
                case 4:
                  if (instance.application.education.length === 0) {
                    setTimeout(function () {
                      instance.add_education()
                    }, 250)
                  }
                  instance.application.step++
                  break
                case 5:
                  if (instance.application.employment.length === 0) {
                    setTimeout(function () {
                      instance.add_employment()
                    }, 250)
                  }
                  instance.application.step++
                  break
                case 6:
                  if (typeof instance.application.professional_references === 'undefined' || instance.application.professional_references.length === 0) {
                    setTimeout(function () {
                      instance.add_reference()
                    }, 250)
                  }
                  instance.application.step++
                  break
                case 11:
                  instance.complete = true
                  store.commit('updateApplicationId', {
                    application_id: -1
                  })
                  break
                default:
                  instance.application.step++
                  break
              }
            }
            this.showSpinner = false
          })
          .catch(error => {
            console.error(error)
          })
      } else this.showSpinner = false
    },
    validate_section: function (sectionKey) {
      const $ = require('jquery')
      let _valid = true
      this.error_message = ''

      switch (sectionKey) {
        case 'applicant':
          Object.keys(this.application[sectionKey]).forEach((id) => {
            if (!this.field_check($('#' + id))) _valid = false
          })
          if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.application.applicant.email) === false) {
            _valid = false
            $('#email').addClass('field_error')
          }
          break
        case 'position':
          if (typeof this.application[sectionKey] !== 'undefined') {
            Object.keys(this.application[sectionKey]).forEach((id) => {
              if (!this.field_check($('#' + id))) _valid = false
            })
            const now = moment()
            const availableDate = moment(this.application.position.available_date)
            if (availableDate < now) {
              _valid = false
              this.error_message = 'Start date has already passed'
              $('#available_date').addClass('field_error')
            } else if (!this.application.position.full_time && !this.application.position.part_time && !this.application.position.seasonal) {
              _valid = false
              this.error_message = 'Missing employment type'
            }
          }
          break
        case 'education':
          if (Object.keys(this.application.education).length === 0 && !this.adding_item) {
            _valid = false
            this.error_message = 'Current section is not complete.'
          } else {
            ['education_type', 'education_name', 'education_location_city', 'education_location_state', 'education_history_from', 'education_history_to'].forEach((id) => {
              if (!this.field_check($('#' + id))) _valid = false
            })
          }
          break
        case 'eligibility':
          if (!this.application.eligibility.us_eligible) {
            _valid = false
            this.error_message = 'We are sorry! At this time we are only accepting applicants who are eligible for US employment'
          }
          break
        case 'employment':
          if (Object.keys(this.application.employment).length === 0 && !this.adding_item) {
            _valid = false
            this.error_message = 'Current section is not complete.'
          } else {
            ['employment_employer', 'employment_title', 'employment_duties', 'employment_notes', 'employment_location_city', 'employment_location_state', 'employment_history_from', 'employment_history_to'].forEach((id) => {
              if (!this.field_check($('#' + id))) _valid = false
            })
          }
          break
        case 'references':
          if (this.adding_item) {
            _valid = this.is_valid_reference()
            if (Object.keys(this.professional_references).length <= 1) {
              _valid = false
              this.error_message = 'Please provide 3 professional references.'
            }
          }
          break
        case 'military':
          if (this.military.veteran) {
            ['military_branch', 'military_discharge_rank', 'military_discharge_from', 'military_discharge_to', 'military_discharge_type', 'military_discharge_notes'].forEach((id) => {
              if (!this.field_check($('#' + id))) _valid = false
            })
          }
          break
        case 'disclaimer':
          if (this.disclaimer) {
            ['disclaimer_signature'].forEach((id) => {
              if (!this.field_check($('#' + id))) _valid = false
            })
          }
          break
        case 'background_authorization':
          if (!this.background_authorization.consent) {
            _valid = false
            this.error_message = 'You must read and consent to the background check policy.'
          }
          break
        case 'screening_process':
          if (this.screening_process.impairments.value && !this.screening_process.impairments.notes) {
            _valid = false
            this.error_message = 'Please complete missing information'
            $('#screening_impairments').addClass('field_error')
          }
          break
        default:
          if (typeof this.application[sectionKey] !== 'undefined') {
            Object.keys(this.application[sectionKey]).forEach((id) => {
              if (!this.field_check($('#' + id))) _valid = false
            })
          }
          break
      }
      return _valid
    },
    is_valid_reference: function () {
      let _valid = true
      const $ = require('jquery')
      const fields = ['reference_full_name', 'reference_company', 'reference_relationship', 'reference_title']
      fields.forEach((id) => {
        if (!this.field_check($('#' + id))) _valid = false
      })
      return _valid
    },
    validate: function (item) {
      const $ = require('jquery')
      const field = $(item.currentTarget)
      if (field.prop('required') && !field.val()) field.addClass('field_error')
    },
    reset_education: function () {
      this.education = {
        type: -1,
        name: '',
        location: {
          city: '',
          state: -1
        },
        history: {
          from: '',
          to: '',
          graduated: 'No'
        }
      }
    },
    cancel_add_education: function () {
      if (Object.keys(this.application.education).length > 0) {
        this.reset_education()
        this.adding_item = false
        this.editing_item = false
      }
    },
    save_education: function () {
      if (this.adding_item) {
        const _clone = Object.assign({}, this.education)
        _clone.id = Date.now()
        this.application.education.push(_clone)
      } else if (this.editing_item) {
        const idx = this.application.education.findIndex((item) => item.id === this.education.id)
        if (idx > -1) this.application.education[idx] = this.education
      }
      // save
      this.save()
        .then(response => {
          // advance
          if (response) {
            this.adding_item = false
            this.editing_item = false
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
    add_education: function () {
      this.adding_item = true
      this.editing_item = false
      this.reset_education()
    },
    delete_education: function (school) {
      const idx = this.application.education.findIndex((item) => item.name === school.name)
      if (idx > -1) this.application.education.splice(idx, 1) // save
      this.save()
        .catch(error => {
          console.error(error)
        })
    },
    edit_education: function (item) {
      this.editing_item = true
      this.adding_item = false
      this.education = Object.assign({}, item)
    },
    reset_employment: function () {
      this.employment = {
        employer: '',
        title: '',
        duties: '',
        notes: '',
        phone: '',
        location: {
          city: '',
          state: -1
        },
        pay: {
          start: '',
          end: ''
        },
        history: {
          from: '',
          to: ''
        }
      }
    },
    cancel_add_employment: function () {
      if (Object.keys(this.application.employment).length > 0) {
        this.reset_employment()
        this.adding_item = false
        this.editing_item = false
      }
    },
    save_employment: function () {
      if (this.adding_item) {
        const _clone = Object.assign({}, this.employment)
        _clone.id = Date.now()
        this.application.employment.push(_clone)
      } else if (this.editing_item) {
        const idx = this.application.employment.findIndex((item) => item.id === this.employment.id)
        if (idx > -1) this.application.employment[idx] = this.employment
      }
      // save
      this.save()
        .then(response => {
          // advance
          if (response) {
            this.adding_item = false
            this.editing_item = false
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
    add_employment: function () {
      this.editing_item = false
      this.adding_item = true
      this.reset_employment()
    },
    edit_employment: function (item) {
      this.editing_item = true
      this.adding_item = false
      this.employment = Object.assign({}, item)
    },
    delete_employment: function (job) {
      const idx = this.application.employment.findIndex((item) => item.employer === job.employer)
      if (idx > -1) this.application.employment.splice(idx, 1)
      // save
      this.save()
        .catch(error => {
          console.error(error)
        })
    },
    reset_reference: function () {
      this.reference = {
        full_name: '',
        relationship: '',
        company: '',
        title: '',
        contact: {
          email: '',
          phone: ''
        }
      }
    },
    cancel_add_reference: function () {
      if (Object.keys(this.professional_references).length > 0) {
        this.adding_item = false
        this.editing_item = false
        this.reset_reference()
      }
    },
    save_reference: function () {
      if (this.adding_item) {
        const _clone = Object.assign({}, this.reference)
        _clone.id = Date.now()
        this.professional_references.push(_clone)
      } else if (this.editing_item) {
        const idx = this.professional_references.findIndex((item) => item.id === this.reference.id)
        if (idx > -1) this.professional_references[idx] = this.reference
      }

      // save
      this.save()
        .then(response => {
          // advance
          if (response) {
            this.adding_item = false
            this.editing_item = false

            if (this.professional_references.length < 3) {
              const instance = this
              setTimeout(function () {
                instance.add_reference()
              }, 500)
            }
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
    add_reference: function () {
      this.adding_item = true
      this.editing_item = false
      this.reset_reference()
    },
    edit_reference: function (item) {
      this.editing_item = true
      this.adding_item = false
      this.reference = Object.assign({}, item)
    },
    delete_reference: function (reference) {
      const idx = this.application.professional_references.findIndex((item) => item.id === reference.id)
      if (idx > -1) this.application.professional_references.splice(idx, 1)
      // save
      this.save()
        .catch(error => {
          console.error(error)
        })
    },
    add_previous_address: function () {
      this.reset_previous_address()
      this.background_authorization.previous_addresses.push(this.previous_address)
    },
    add_previous_name: function () {
      this.reset_previous_name()
      this.background_authorization.previous_names.push(this.previous_name)
    },
    reset_previous_address: function () {
      this.previous_address = {
        address: '',
        from: '',
        to: ''
      }
    },
    reset_previous_name: function () {
      this.previous_name = {
        name: '',
        from: '',
        to: ''
      }
    },
    delete_previous_address: function (item) {
      this.background_authorization.previous_addresses = this.background_authorization.previous_addresses.filter(address => address.address.toLowerCase() !== item.address.toLowerCase())
    },
    delete_previous_name: function (item) {
      this.background_authorization.previous_names = this.background_authorization.previous_names.filter(name => name.name.toLowerCase() !== item.name.toLowerCase())
    }
  },
  computed: {
    title: function () {
      let _title = ''
      if (this.application.step === 1) _title = 'Applicant Information'
      else if (this.application.step === 2) _title = 'Position Details'
      else if (this.application.step === 3) _title = 'Employment Eligibility'
      else if (this.application.step === 4) _title = 'Screening Process'
      else if (this.application.step === 5) _title = 'Education'
      else if (this.application.step === 6) _title = 'Employment History'
      else if (this.application.step === 7) _title = 'References'
      else if (this.application.step === 8) _title = 'Military Service'
      else if (this.application.step === 9) _title = 'Background Check Consent'
      else if (this.application.step === 10) _title = 'Disclaimer'
      else _title = 'Thank you for applying!'
      return 'Step ' + this.application.step + ': ' + _title
    },
    reference_idx_computed: function () {
      if (this.professional_references.length === 0) return '1st'
      else if (this.professional_references.length === 1) return '2nd'
      else if (this.professional_references.length === 2) return '3rd'
      else return (this.professional_references.length + 1) + 'th'
    },
    reference_label_computed: function () {
      if (this.professional_references.length === 0) return 'Please provide at least 3 professional references.'
      else if (this.professional_references.length < 3) {
        return 'Please provide ' + (3 - this.professional_references.length) + ' more professional references.'
      }
      return ''
    }
  },
  mounted () {
    this.disclaimer.date = moment().format('MM/DD/YYYY')
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap');
section.main article div.thumb {
  background-image: url("/images/job_interview.jpg");
}
section.main article div.apply {
  text-align: center;
  margin: 10px 0 30px 0;
}
section.main article div.apply button {
  font-size: 1.5em;
  font-weight: 600;
}
section.main article div.caption {
  font-size: .9em;
}
#application {
  z-index: 13;
  background-color: rgba(50,50,50,1);
  width: 90%;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  border-radius: 15px;
  box-shadow: 10px 10px 25px #222;
}
#application div.title {
  background-color: rgba(100,100,100,.25);
  width: 100%;
  height: auto;
  padding: 15px 30px;
  position: relative;
}
#application div.title button.close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: white;
}
#application div.title h3 {
  color: #2a7afa;
  font-size: 2em;
  letter-spacing: 1px;
}
#application div.screens {
  padding: 30px 30px 0 30px;
}
#application input[type="text"], #application input[type="number"], #application select {
  background-color: white;
  padding: 10px 15px;
  border-radius: 6px;
  font-size: 1.2em;
  text-transform: capitalize;
  margin-bottom: 24px;
  border: solid 3px transparent;
}
#application div.screens input[type="text"].field_error, #application div.screens select.field_error {
  background-color: rgba(255,125,125,1);
  color: white;
  border: solid 3px red;
}
#dob, #email, #available_date {
  text-transform: none !important;
}
#application div.actions {
  padding: 0 30px 30px 30px;
  text-align: right;
}
#application div.actions button {
  font-size: 1.2em;
  margin-left: 20px;
}
#application div.checkbox_group {
  line-height: 1.5em;
  font-size: 1.3em;
}
#application div.checkbox_instructions {
  text-align: right;
  width: 100%;
}
#application article.eligibility div.checkbox_group {
  margin: 15px 0 0 0;
  width: 100%;
}
#application div.checkbox_group input {
  width: 33%;
  text-transform: none;
  margin: 0 20px 0 0;
}
#application article.eligibility {
  padding-bottom: 20px;
}
#application article.screening_process {
  padding-bottom: 20px;
}
#application .v-input--selection-controls {
  margin: 8px 0 0 0;
}
#application .v-input--checkbox {
  display: inline-block;
  margin-left: 10px;
  padding: 0;
}
#application .v-input--checkbox {
  margin-left: 20px;
  float: right;
}
#application .checkbox_group span {
  float: left;
}
#application div.added_history {
  padding: 20px 20px 0 20px;
  background-color: rgba(100,100,100,1);
  border-bottom: dashed 1px #fff;
  margin-bottom: 0;
  color: white;
}
#application div.headings {
  background-color: rgba(200,200,200,1);
  margin: 20px 0 0 0;
  color: black;
  font-weight: 600;
}
#application div.added_history:last-child {
  border-bottom: 0;
  margin-bottom: 20px;
}
#application div.added_history span {
  padding: 0 0 15px 0;
  display: inline-block;
}
#save_employment, #save_education, #save_reference {
  margin-left: 20px;
}
.add_cancel {
  margin: 0 0 20px 0;
}
div.veteran {
  margin-bottom: 20px;
}
div.disclaimer_text {
  font-size: 1.2em;
  margin-bottom: 40px;
}
#disclaimer_signature {
  font-family: 'Homemade Apple', cursive;
}
#application article h3 {
  margin-bottom: 20px;
}
#application .error_message {
  color: rgba(255,125,125,1);
  font-weight: 600;
}
#application .progress_container {
  display: inline-block;
  width: 400px;
}
#application .progress_container > span {
  display: inline-block;
  margin: 0 20px 0 0;
}

#first, #last, #middle, #dob { width: 24%; }
#address1, #address2 { width: 49%; }
#city, #state, #zip, #email, #phone, #drivers_license { width: 32%; }

#available_date, #desired_pay {
  width: 29%;
}

/* CHECKBOX GROUP */
article.position div.checkbox_group {
  width: 39%;
}
article.screening_process div.checkbox_group,
  article.background_authorization div.checkbox_group {
  width: 100%;
  margin: 0 0 2px 0;
}
article.military div.checkbox_group {
  width: 100%;
  margin: 10px 0 20px 0;
}
article.screening_process div.checkbox_group:last-child {
  margin: 10px 0 2px 0;
}
#application span.additional_info_disclaimer {
  line-height: 1em !important;
  font-size: .9em;
  color: rgba(255,255,255,.5);
  display: block;
  margin: 8px 0;
}

/* OVERRIDES */
#application div.additional_info {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* ELIGIBILITY */
.half_width {
  width: 49%;
}
.full_width {
  width: 100%;
}

/* POSITION */
#application article.position div.disclaimer_text {
  display: block;
  width: 100%;
  font-size: .8em;
  clear: both;
  margin-bottom: 0;
}
#position_title {
  width: 100%;
}

#screening_impairments {
  width: 100% !important;
}

/* BACKGROUND AUTHORIZATION */
article.background_authorization div.previous_addresses, article.background_authorization div.previous_names {
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 20px 0 0;
}
article.background_authorization div.previous_addresses > div, article.background_authorization div.previous_names > div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
article.background_authorization div.previous_addresses > div:first-child, article.background_authorization div.previous_names > div:first-child {
  margin: 10px 0 0 0;
  padding: 10px 0 0 0;
}
article.background_authorization div.previous_names .previous_name {
  width: 56% !important;
}
article.background_authorization div.previous_addresses .previous_address {
  width: 56% !important;
}
article.background_authorization div.previous_addresses .previous_address_from,
  article.background_authorization div.previous_addresses .previous_address_to {
  width: 18% !important;
}
article.background_authorization div.previous_names .previous_name_from,
  article.background_authorization div.previous_names .previous_name_to {
  width: 18% !important;
}
article.background_authorization div.previous_addresses .remove_previous_address {
  margin: 10px 0 0 0;
}
article.background_authorization div.heading {
  margin: 10px 0 0 0;
  font-size: 1.2em;
}
article.background_authorization div.heading > span {
  display: inline-block;
  margin: 0 20px 0 0;
}
article.background_authorization div.legal {
  padding: 0 0 20px 0;
}
article.background_authorization div.legal p {
  letter-spacing: unset;
  font-size: .9em;
  line-height: unset;
  margin: 0 0 20px 0;
  text-align: justify;
}
article.background_authorization div.checkbox_group:last-child {
  margin: 0 0 15px 0;
}

/* POLICIES & RULES */
#application article.policies_rules div.checkbox_group {
  width: 100%;
}
#application article.policies_rules div.checkbox_group div.policy_label {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
#application article.policies_rules div.checkbox_group div.policy_step {
  margin: 20px 0 15px 0;
  text-align: center;
}
#application article.policies_rules div.checkbox_group div.policy_step button {
  margin: 0 0 10px 0;
}

#application article.education div.added_history span:nth-child(1) { width: 5%; }
#application article.education div.added_history span:nth-child(2) { width: 24%;}
#application article.education div.added_history span:nth-child(3) { width: 24%;}
#application article.education div.added_history span:nth-child(4) { width: 24%;}
#application article.education div.added_history span:nth-child(5) { width: 9%;}
#application article.education div.added_history span:nth-child(6) { width: 9%;}

#application article.employment div.added_history span:nth-child(1) { width: 5%; }
#application article.employment div.added_history span:nth-child(2) { width: 19%;}
#application article.employment div.added_history span:nth-child(3) { width: 19%;}
#application article.employment div.added_history span:nth-child(4) { width: 19%;}
#application article.employment div.added_history span:nth-child(5) { width: 9%;}
#application article.employment div.added_history span:nth-child(6) { width: 9%;}
#application article.employment div.added_history span:nth-child(7) { width: 9%;}

#application article.references div.added_history span:nth-child(1) { width: 5%; }
#application article.references div.added_history span:nth-child(2) { width: 22%;}
#application article.references div.added_history span:nth-child(3) { width: 22%;}
#application article.references div.added_history span:nth-child(4) { width: 22%;}
#application article.references div.added_history span:nth-child(5) { width: 22%;}
#application article.references span.reference_label {
  display: inline-block;
  margin: 0 0 0 20px;
}
#application article.references span.references_instructions {
  display:block;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(255,255,255,.5);
  margin: 0 0 10px 0;
  width: 100%;
}
#application article.references div.flex-container:first-child {
  margin: 0 0 20px 0;
}

/* MILITARY SERVICE */
#military_discharge_rank, #military_branch {
  width: 49%;
}
#military_discharge_type, #military_discharge_from, #military_discharge_to {
  width: 32%;
}
#military_discharge_notes {
  width: 100%;
}
#application article div.hiring-packet { margin: 20px 0 0 0; }
#application article div.hiring-packet a.download {
  margin: 20px 0 0 0;
  display: block;
  background-color: #fff;
  border-radius: 6px;
  border: solid 1px #2a7afa;
  width: 200px;
  padding: 6px;
  color: black;
  text-align: center;
}
#application article div.hiring-packet a { color: #fff; text-decoration: none; }

.blue {
  color: #2a7afa;
}
@media screen and (max-width: 1460) {
  #available_date, #desired_pay {
    width: 24%;
  }
}
@media screen and (max-width: 1160px) {
  .checkbox_group {
    font-size: 1em !important;
  }
  .checkbox_group span {
    line-height: 2.4em;
  }
  .actions {
    margin-top: 20px;
  }
  #application article.education div.added_history span:nth-child(1) { width: 8%; }
  #application article.education div.added_history span:nth-child(2) { width: 23%;}
  #application article.education div.added_history span:nth-child(3) { width: 23%;}
  #application article.education div.added_history span:nth-child(4) { width: 23%;}
  #application article.education div.added_history span:nth-child(5) { width: 9%;}
  #application article.education div.added_history span:nth-child(6) { width: 9%;}

  #application article.employment div.added_history span:nth-child(1) { width: 8%; }
  #application article.employment div.added_history span:nth-child(2) { width: 14%;}
  #application article.employment div.added_history span:nth-child(3) { width: 19%;}
  #application article.employment div.added_history span:nth-child(4) { width: 14%;}
  #application article.employment div.added_history span:nth-child(5) { width: 10%;}
  #application article.employment div.added_history span:nth-child(6) { width: 13%;}
  #application article.employment div.added_history span:nth-child(7) { width: 13%;}

  #application article.references div.added_history span:nth-child(1) { width: 8%; }
  #application article.references div.added_history span:nth-child(2) { width: 22%;}
  #application article.references div.added_history span:nth-child(3) { width: 22%;}
  #application article.references div.added_history span:nth-child(4) { width: 22%;}
  #application article.references div.added_history span:nth-child(5) { width: 22%;}
}
@media screen and (max-width: 920px){
  #application article.position div.checkbox_group {
    display: block;
    width: 100% !important;
  }
  #available_date, #desired_pay {
    width: 49%;
  }
}
@media screen and (max-width: 850px){
  #application article.eligibility div.checkbox_group:nth-child(3) input {
    width: 49% !important;
    margin-right: 1% !important;
  }
  #application article.education div.added_history span:nth-child(1) { width: 10%; }
  #application article.employment div.added_history span:nth-child(1) { width: 10%; }
  #application article.references div.added_history span:nth-child(1) { width: 10%; }
}
@media screen and (max-width: 650px){
  #application div.title h3 {
    font-size: 1.6em;
  }

  #application input[type="text"] {
    font-size: 1.1em;
  }
  #first, #last, #middle, #dob, #email, #phone { width: 49%; }
  #address1, #address2 { width: 100%; }
  #city, #state, #zip { width: 32%; }

  #application article.education div.added_history span:nth-child(1) { width: 12%; }
  #application article.education div.added_history span:nth-child(2) { display: none }
  #application article.education div.added_history span:nth-child(6) { display: none; }

  #application article.employment div.added_history span:nth-child(1) { width: 12%; }
  #application article.employment div.added_history span:nth-child(3) { width: 25%; }
  #application article.employment div.added_history span:nth-child(4) { display: none }
  #application article.employment div.added_history span:nth-child(5) { display: none }

  #application article.references div.added_history span:nth-child(1) { width: 12%; }
  #application article.references div.added_history span:nth-child(3) { display: none }

  #application .disclaimer_text {
    font-size: 1em;
  }
}
@media screen and (max-width: 480px){
  #application div.screens {
    /* height: 65vh; */
    max-height: 65vh;
    overflow-y: scroll;
    padding: 30px 10px 0px 10px;
  }
  #application div.title h3 {
    font-size: 1.3em;
  }
  #application div.checkbox_instructions {
    text-align: left;
    width: 100%;
    padding: 10px;
    background-color: rgba(255,255,255,.5);
    margin: 0 0 10px 0;
    border-radius: 5px;
  }

  /* POSITION */
  #application article.position div.checkbox_group span {
    float: none;
    display: block;
  }
  #application article.position div.checkbox_group .v-input--checkbox {
    float: none;
    display: inline-block;
    margin-left: 0;
    margin-right: 20px;
  }
  #application article.position div.disclaimer_text {
    font-size: .9em;
    margin: 10px 0 0 0;
  }
  #position_title, #available_date, #desired_pay {
    width: 100%;
  }

  #first, #last, #middle, #dob, #email, #phone, #drivers_license { width: 100%; }
  #address1, #address2 { width: 100%; }
  #city, #state, #zip { width: 100%; }

  /* ELIGIBILITY */
  #application article.eligibility div.checkbox_group {
    padding: 4px 0 10px 0;
    border-bottom: solid 1px #ccc;
    width: 100%;
  }
  #application article.eligibility div.checkbox_group > span {
    width: 80%;
    line-height: 1.2em;
  }
  #application article.eligibility div.checkbox_group > div {
    width: 10%;
    margin: 0;
  }
  .half_width, .full_width {
    width: 100%;
  }

  /* SCREENING PROCESS */
  #application article.screening_process div.checkbox_group {
    padding: 4px 0 10px 0;
    border-bottom: solid 1px #ccc;
  }
  #application article.screening_process div.checkbox_group > span {
    width: 80%;
    line-height: 1.2em;
  }
  #application article.screening_process div.checkbox_group > div {
    width: 10%;
    margin: 0;
  }

  #application div.added_history { padding: 10px; }
  #application div.headings span { padding: 0; }
  #application article.education div.added_history span:nth-child(1) { width: 25%; }
  #application article.education div.added_history span:nth-child(3) { width: 40%; }
  #application article.education div.added_history span:nth-child(4) { display: none; }
  #application article.education div.added_history span:nth-child(5) { width: 35%; }
  .add_cancel { text-align: center }

  #application article.employment div.added_history span:nth-child(1) { width: 25%; }
  #application article.employment div.added_history span:nth-child(2) { width: 40%; }
  #application article.employment div.added_history span:nth-child(3) { display: none }
  #application article.employment div.added_history span:nth-child(4) { display: none }
  #application article.employment div.added_history span:nth-child(5) { display: none }
  #application article.employment div.added_history span:nth-child(6) { display: none }
  #application article.employment div.added_history span:nth-child(7) { width: 35% }

  #application article.references div.added_history span:nth-child(1) { width: 25%; }
  #application article.references div.added_history span:nth-child(2) { display: none }
  #application article.references div.added_history span:nth-child(3) { display: none }
  #application article.references div.added_history span:nth-child(4) { width: 40% }
  #application article.references div.added_history span:nth-child(5) { width: 35% }

  #application .disclaimer_text {
    font-size: 1em;
  }

  #application div.actions {
    padding: 15px 0 20px 0;
    margin: 0;
    text-align: center;
  }

  /* REFERENCES */
  #application article.references span.reference_label {
    display: block;
    margin: 10px 0 0 0;
  }

  /* BACKGROUND AUTHORIZATION */
  #application article.background_authorization div.checkbox_group > span {
    width: 80%;
    line-height: 1.2em;
  }
  #application article.background_authorization div.checkbox_group > div {
    width: 10%;
    margin: 0;
  }
  article.background_authorization div.previous_names .previous_name {
    width: 100% !important;
  }
  article.background_authorization div.previous_addresses .previous_address {
    width: 100% !important;
  }
  article.background_authorization div.previous_addresses .previous_address_from,
    article.background_authorization div.previous_addresses .previous_address_to {
    width: 40% !important;
  }
  article.background_authorization div.previous_names .previous_name_from,
    article.background_authorization div.previous_names .previous_name_to {
    width: 40% !important;
  }
  article.background_authorization div.previous_addresses, article.background_authorization div.previous_names {
    width: 100%;
    max-height: unset;
    overflow-y: unset;
    overflow-x: unset;
    padding: 0;
  }
  article.background_authorization div.heading {
    width: 100%;
  }
  article.background_authorization div.heading button {
    float: right;
  }

  /* SCREENING PROCESS */
  #application article.military div.checkbox_group {
    padding: 4px 0 10px 0;
  }
  #application article.military div.checkbox_group > span {
    width: 80%;
    line-height: 1.2em;
  }

  /* POLICIES & RULES */
  #application article.policies_rules ul li {
    line-height: 1.2em;
  }
  #application article.policies_rules h5 {
    letter-spacing: 1em;
    margin: 10px 0 0 0;
  }
  #application article.policies_rules div.checkbox_group {
    padding: 4px 0 10px 0;
    border-bottom: solid 1px #ccc;
    width: 100%;
  }
  #application article.policies_rules div.checkbox_group div.policy_label {
    width: 100%;
    line-height: 1.1em;
  }
  #application article.policies_rules div.checkbox_group div.policy_step {
    margin: 15px 0 15px 0;
  }

  #application div.actions span.error_message {
    padding: 0 10px 10px 10px;
    display: block;
  }

  /* MILITARY SERVICE */
  #military_discharge_rank, #military_branch, #military_discharge_type, #military_discharge_from, #military_discharge_to, #military_discharge_notes {
    width: 100% !important;
    margin: 0 0 8px 0 !important;
  }
}
</style>
