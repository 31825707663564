<template>
  <div class="flex-container added_history">
    <div class="employer_name field">
      <label for="">Employer Name</label>
      <input type="text" id="employment_employer" v-model="tmp_employment.employer" placeholder="Employer name" @blur="validate($event)" required />
    </div>
    <div class="job_title field">
      <label for="">Job Title</label>
      <input type="text" id="employment_title" v-model="tmp_employment.title" placeholder="Job Title" @blur="validate($event)" required />
    </div>
    <div class="duties field">
      <label for="">Duties &amp; responsibilities</label>
      <input type="text" id="employment_duties" v-model="tmp_employment.duties" placeholder="Duties &amp; responsibilities" @blur="validate($event)" required />
    </div>
    <div class="notes field">
      <label for="">Reason for leaving</label>
      <input type="text" id="employment_notes" v-model="tmp_employment.notes" placeholder="Reason for leaving" @blur="validate($event)" required />
    </div>
    <div class="city field">
      <label for="">City</label>
      <input type="text" id="employment_location_city" v-model="tmp_employment.location.city" placeholder="City" @blur="validate($event)" required />
    </div>
    <div class="state field">
      <label for="">State</label>
      <select id="employment_location_state" v-model="tmp_employment.location.state" @blur="validate($event)" required >
        <option value="-1">- Select State-</option>
        <option :value="state" v-for="(state, i) in $store.state.states" :key="'state_' + i">{{ state }}</option>
      </select>
    </div>
    <div class="phone field">
      <label for="">Phone</label>
      <input type="text" v-model="tmp_employment.phone" placeholder="Phone" />
    </div>
    <div class="employed_dates field">
      <label for="">Date employed from</label>
      <input type="text" id="employment_history_from" v-model="tmp_employment.history.from" placeholder="Date employed from" @blur="validate($event)" required />
    </div>
    <div class="employed_dates field">
      <label for="">Date employed to</label>
      <input type="text" id="employment_history_to" v-model="tmp_employment.history.to" placeholder="Date employed to" @blur="validate($event)" required />
    </div>
    <div class="pay_range field">
      <label for="">Start pay</label>
      <input type="text" v-model="tmp_employment.pay.start" placeholder="Start pay" />
    </div>
    <div class="pay_range field">
      <label for="">End pay</label>
      <input type="text" v-model="tmp_employment.pay.end" placeholder="End pay" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'applicationEmploymentHistoryItem',
  props: ['employment'],
  data: () => ({
    tmp_employment: {}
  }),
  methods: {
    validate: function (item) {
      const $ = require('jquery')
      const field = $(item.currentTarget)
      this.field_check((field))
    }
  },
  created () {
    this.tmp_employment = this.employment
  }
}
</script>

<style scoped>
input[type="text"], input[type="number"], select {
  background-color: white;
  padding: 8px 15px;
  border-radius: 6px;
  font-size: 1.1em;
  text-transform: capitalize;
  margin-bottom: 10px;
  border: solid 3px transparent;
  width: 100%;
  display: block;
}
input[type="text"].field_error, select.field_error {
  background-color: rgba(255,125,125,1);
  color: white;
  border: solid 3px red;
}
label {
  display: block;
  width: 100%;
  margin: 0 0 6px 0;
  color: black;
}
.added_history {
  background-color: rgba(200,200,200,1) !important;
  padding-bottom: 15px !important;
}
.field { width: 49%; }
.duties, .notes { width: 100%; }
.city, .state, .phone { width: 32%; }
.employed_dates, .pay_range { width: 24%; }

@media screen and (max-width: 650px){
  .city, .state, .phone { width: 32%; }
  .employed_dates, .pay_range { width: 49%; }
}
@media screen and (max-width: 480px){
  .city { width: 100% }
  .state, .phone { width: 49%; }
  .employed_dates, .pay_range { width: 49%; }
}
@media screen and (max-width: 380px){
  .employer_name, .job_title, .city, .state, .phone, .employed_dates, .pay_range { width: 100%; }
}
</style>
