<template>
  <div class="flex-container added_history">
    <div class="education_type field">
      <label for="">Education Type</label>
      <select v-model="tmp_education.type" id="education_type" @change="validate($event)" required>
        <option value="-1">- Education Type -</option>
        <option v-for="(item, i) in ['High School','College','Other']" :key="'education_type_' +i">{{ item }}</option>
      </select>
    </div>
    <div class="school_name field">
      <label for="">School name</label>
      <input type="text" id="education_name" v-model="tmp_education.name" placeholder="School name" @blur="validate($event)" required />
    </div>
    <div class="graduated field checkbox_group">
      <label for="">Did you graduate</label>
      <select v-model="tmp_education.history.graduated" id="education_graduated" @change="validate($event)" required>
        <option v-for="(item, i) in ['Yes','No']" :value="item" :key="'education_graduated_' +i">{{ item }}</option>
      </select>
      <!-- <v-checkbox dark v-model="tmp_education.history.graduated" dense hide-details></v-checkbox> -->
    </div>
    <div class="city field">
      <label for="">City</label>
      <input type="text" id="education_location_city" v-model="tmp_education.location.city" placeholder="City" @blur="validate($event)" required />
    </div>
    <div class="state field">
      <label for="">State</label>
      <select id="education_location_state" v-model="tmp_education.location.state" @blur="validate($event)" required >
        <option value="-1">- Select State-</option>
        <option :value="state" v-for="(state, i) in $store.state.states" :key="'state_' + i">{{ state }}</option>
      </select>
    </div>
    <div class="dates field">
      <label for="">Date attended from</label>
      <input type="text" id="education_history_from" v-model="tmp_education.history.from" placeholder="Date attended from" @blur="validate($event)" required />
    </div>
    <div class="dates field">
      <label for="">Date attended to</label>
      <input type="text" id="education_history_to" v-model="tmp_education.history.to" placeholder="Date attended to" @blur="validate($event)" required />
    </div>
  </div>
</template>

<script>
export default {
  name: 'applicationtmp_education_history_item',
  props: ['education'],
  data: () => ({
    tmp_education: {}
  }),
  methods: {
    validate: function (item) {
      const $ = require('jquery')
      const field = $(item.currentTarget)
      this.field_check((field))
    }
  },
  created () {
    this.tmp_education = this.education
  }
}
</script>

<style scoped>
input[type="text"], input[type="number"], select {
  background-color: white;
  padding: 8px 15px;
  border-radius: 6px;
  font-size: 1.1em;
  text-transform: capitalize;
  margin-bottom: 10px;
  border: solid 3px transparent;
  width: 100%;
  display: block;
}
input[type="text"].field_error, select.field_error {
  background-color: rgba(255,125,125,1);
  color: white;
  border: solid 3px red;
}
label {
  display: block;
  width: 100%;
  margin: 0 0 6px 0;
  color: black;
}
.added_history {
  background-color: rgba(200,200,200,1) !important;
  padding-bottom: 15px !important;
}
.field {
  width: 49%;
}
.education_type, .school_name, .graduated {
  width: 32%;
}
.city, .state, .dates {
  width: 24%;
}
@media screen and (max-width: 650px){
  .education_type { width: 100%; }
  .school_name { width: 70%; }
  .graduated { width: 28% }
  .city, .state, .dates { width: 49%; }
}
@media screen and (max-width: 415px){
  .education_type, .school_name, .graduated, .city, .state { width: 100%; }
  .dates { width: 49%; }
}
@media screen and (max-width: 380px){
  .school_name, .graduated, .city, .state, .dates { width: 100%; }
}
</style>
